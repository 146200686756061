import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  InputLabel,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import toast from "../../../helper/toast";
import { useDispatch } from "react-redux";
import { forgotApi } from "../../../ReduxStore/actions/auth.action";

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
});
const initialValues = {
  email: "",
};

function ForgotPassword() {
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    try {
      let response = await dispatchRedux(forgotApi(values)).unwrap();
      toast(response.message, 200);
      navigate("/login");
    } catch (error) {
      toast(error.data.message, error.status);
    }
  };
  return (
    <Container maxWidth="sm">
      <Paper>
        <Box marginTop={"20vh"} padding={"20px"}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box textAlign={"center"}>
                  <Typography variant="h2" fontWeight={600} gutterBottom>
                    Forgot Password?
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    Enter your email to get a password reset link
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gap="10px"
                  padding={"20px"}
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box gridColumn={"span 4"}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                </Box>
                <Box mt="20px" width={"50%"} margin={"auto"}>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      fontSize: "15px",
                    }}
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
                <Box mt="20px" textAlign={"center"}>
                  <Typography variant="h5">
                    Remember your password?{" "}
                    <Link to={"/login"}>
                      <Typography
                        color={colors.blueAccent[500]}
                        component={"span"}
                        variant="h5"
                      >
                        Login
                      </Typography>
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPassword;
