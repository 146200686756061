import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

function Bio({BioArray}) {
  const { user } = useSelector((store) => store.auth)
  return (
    <>
      <Card>
        <CardContent>
          <Stack
            display="flex"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              sx={{ borderRight: "2px dashed #cccccc" }}
              width="100%"
            >
              <CardMedia
                component="img"
                sx={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                }}
                image="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fHByb2ZpbGV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                alt="pp"
              />
              <Stack
                alignItems="flex-start"
                marginLeft="10px"
                color="#8e8e8e"
                sx={{ wordSpacing: "5px" }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "600", color: "black" }}
                >
                  {user.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  {user.team}
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  {user.profile}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "black",
                    marginTop: "5px",
                  }}
                >
                  Employee ID : {user.employeeID}
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Date of Join : {user.DateOfJoin}
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    marginTop: "30px",
                    fontSize: "16px",
                    textTransform: "none",
                    background: "",
                  }}
                >
                  Send Message
                </Button>
              </Stack>
            </Box>
            <Box width="100%">
              <Table>
                <TableBody>
                  {BioArray.map((el, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            paddingRight: "15px",
                          }}
                        >
                          {el.key}
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>
                          {el.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default Bio;
