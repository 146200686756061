import React from "react";
import { Warning } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const classes = {
  container: {
    textAlign: "center",
    marginTop: "200px",
  },

  typo: {
    fontSize: "10em",
    color: "#ff9b44",
    fontWeight: "700",
  },
  typo1: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "12px",
    wordSpacing: "10px",
  },
  typo2: {
    fontSize: "16px",
    marginBottom: "30px",
    wordSpacing: "8px",
  },
  warning: {
    marginRight: "15px",
  },
  btn: {
    background: "linear-gradient(to right, #ff9b44, #fc6075)",
    borderRadius: "25px",
    padding: "10px 20px",
    color: "#ffffff",
    fontSize: "18px",
    textTransform: "none",
    minWidth: "200px",
    wordSpacing: "6px",
  },
};


function Error({ status, message }) {

  return (
    <>
      <Container sx={classes.container}>
        <Typography sx={classes.typo}>{status}</Typography>
        <Box>
          <Typography sx={classes.typo1}>
            <Warning sx={classes.warning} />
            {message}
          </Typography>
          <Typography sx={classes.typo2}>
            The page you requested was not found
          </Typography>
          <Link to={"/"}>
            <Button sx={classes.btn}>Back to Home</Button>
          </Link>
        </Box>
      </Container>
    </>
  );
}

export default Error;
