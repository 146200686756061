import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
// const EmergencyContactArray = [
//   { key: "Primary", value: "" },
//   { key: "Name", value: "John Doe" },
//   { key: "Relationship", value: "Father" },
//   { key: "Phone", value: "9876543210, 9876543210" },
//   { key: "Seconadary", value: "" },
//   { key: "Name", value: "Karen Wills" },
//   { key: "Relationship  ", value: "Brother" },
//   { key: "Phone", value: "9876543210, 9876543210" },
// ];

function EmergencyContact({ EmergencyContactArray }) {
  return (
    <div>
      <Box>
        <Paper sx={{ padding: "16px" }}>
          <Typography
            variant="h4"
            sx={{
              color: "#1f1f1f",
              fontSize: "22px",
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Emergency Contact
          </Typography>

          <Box>
            <Table>
              <TableBody>
                {EmergencyContactArray.map((el, i) => {
                  return (
                    <Fragment key={i}>
                      <TableRow sx={{ paddingTop: "50px" }}>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            fontWeight: "600",
                            padding: "0px 0px 5px 0px ",
                            width: "40%",
                            color: "#333333",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            padding: "0px 0px 5px 0px ",
                            width: "60%",
                          }}
                        >
                          {el.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            fontWeight: "600",
                            padding: "0px 0px 5px 0px ",
                            width: "40%",
                            color: "#333333",
                          }}
                        >
                          Relationship
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            padding: "0px 0px 5px 0px ",
                            width: "60%",
                          }}
                        >
                          {el.relationship}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            fontWeight: "600",
                            padding: "0px 0px 5px 0px ",
                            width: "40%",
                            color: "#333333",
                          }}
                        >
                          Phone
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: "16px",
                            padding: "0px 0px 5px 0px ",
                            width: "60%",
                          }}
                        >
                          {el.phoneNumber}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}

export default EmergencyContact;
