import { createAsyncThunk} from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/configVariables";
import axios from "axios";

export const loginApi = createAsyncThunk(
  "posts/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/v1/login`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const signupApi = createAsyncThunk("posts/signup", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/v1/register`,
      data
    );
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
});

export const forgotApi = createAsyncThunk("posts/forgot", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/v1//password/forgot`,
      data
    );
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
})

export const resetApi = createAsyncThunk("put/reset", async (data,id, { rejectWithValue }) => {
 try{
    
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/user/v1/password/reset/${id}`,
    data
  );
  return response.data;
 } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
})
