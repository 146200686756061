import React from "react";
import "./dashboard.css";
import TimeSheet from "./Component/TimeSheet";
import { Box, Container } from "@mui/material";
import Header from "../../../components/Header";
import Stastics from "./Component/Stastics";
import TodayActivity from "./Component/TodayActivity";
import { Projects, UpcomingHoliday, YourLeave } from "./Component/widget";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  showActivityApi,
  totalTimeSpentApi,
} from "../../../ReduxStore/actions/attendance.action";
import { upcomingHolidayApi } from "../../../ReduxStore/actions/holiday.action";
import { endOfDay, format } from "date-fns";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customClass: {
    maxWidth: "1920px",
  },
}));

function Employee() {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const { data } = useSelector((store) => store.attendance);

  useEffect(() => {
    const data = { employeeID: user._id };
    console.log()
    dispatchRedux(showActivityApi(data));
    dispatchRedux(totalTimeSpentApi(data));
  }, [data.checkIn, data.checkOut]);
  useEffect(() => {
    const todayDate = format(new Date(), "yyyy MM dd");
    dispatchRedux(upcomingHolidayApi(todayDate));
  }, []);

  return (
    

    <Container className={classes.customClass}>
      <Header title={"attendance"} subtitle={"Dashboard / Attendance"} />
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="auto"
        gap="20px"
      >
        <Box gridColumn={"span 4"}>
          <TimeSheet />
        </Box>
        <Box gridColumn={"span 4"}>
          <Stastics />
        </Box>
        <Box gridColumn={"span 4"} maxHeight={"350px"}>
          <TodayActivity />
        </Box>
        <Box gridColumn={"span 3"}>
          <Projects />
        </Box>
        <Box gridColumn={"span 3"}>
          <YourLeave />
        </Box>
        <Box gridColumn={"span 3"}>
          <UpcomingHoliday />
        </Box>
        <Box gridColumn={"span 3"}>
          <Projects />
        </Box>
      </Box>
    </Container>
  );
}

export default Employee;
