import { useEffect, useReducer } from "react";
import { AuthContext } from "../Context/AuthContext";
import jwt_decode from "jwt-decode";

// ---------------initState--------------
const initState = {
  role: "",
  token: false,
};

// --------------Action---------------
const changeRoleAction = async () => {
  const token = localStorage.getItem("token") || null;
  try {
    let decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    // console.log(error);
  }
};

// -------------types----------------
export const CHANGE_ROLE = "/change/role";
export const CHANGE_TOKEN = "/change/token";

// ------------------reducer-------------
const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CHANGE_ROLE: {
      return {
        ...state,
        role: payload,
      };
    }
    case CHANGE_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }
    default : return  state
  }
};

export function AuthContextProvider({ children }) {
  const [authState, dispatch] = useReducer(reducer, initState);
  const data = {
    authState,
    dispatch,
  };
  useEffect(() => {
    changeRoleAction().then((res) => {
      dispatch({ type: CHANGE_ROLE, payload: res.role });
    });
  }, [authState.token]);
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}
