import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  InputLabel,
  useTheme,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { CHANGE_TOKEN } from "../../../useReducer/AuthReducer";
import { useDispatch } from "react-redux";
import { loginApi } from "../../../ReduxStore/actions/auth.action";
import sendToast from "../../../helper/toast";

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});
const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const { dispatch } = useContext(AuthContext);
  const dispatchRedux = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    // dispatchRedux(loginApi(values))
    try {
      let response = await dispatchRedux(loginApi(values)).unwrap();
      localStorage.setItem("token", response.token);
      dispatch({ type: CHANGE_TOKEN, payload: true });
      sendToast("Login successfully", 200);
      navigate("/");
    } catch (error) {
      console.log("errrororororor", error);
      sendToast(error.data.message, error.status);
    }
  };

  console.log(process.env.REACT_APP_API_BASE_URL)
  return (
    <Container maxWidth="sm">
      <Paper>
        <Box marginTop={"20vh"} padding={"20px"}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box textAlign={"center"}>
                  <Typography variant="h2" fontWeight={600} gutterBottom>
                    Login
                  </Typography>
                  <Typography variant="h4" color={"gray"} gutterBottom>
                    Access to our dashboard
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gap="10px"
                  padding={"20px"}
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box gridColumn={"span 4"}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                  <Box gridColumn={"span 4"}>
                    <Box>
                      <Link to={"/forgot/password"}>
                        <Typography
                          variant="h5"
                          textAlign={"end"}
                          color={"gray"}
                        >
                          Forgot password
                        </Typography>
                      </Link>
                    </Box>
                    <InputLabel>Password</InputLabel>

                    <OutlinedInput
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      // helperText={touched.password && errors.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>
                <Box mt="20px" width={"50%"} margin={"auto"}>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      fontSize: "15px",
                    }}
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
