import { Paper, Container, Box, Typography, Stack } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showActivityApi } from "../../../../ReduxStore/actions/attendance.action";
import { useEffect } from "react";
import { format } from "date-fns";

const classes = {
  timelineItem: {
    "&::before": {
      content: "none", // Remove the default content of ::before pseudo-element
    },
  },
};

function TodayActivity() {
  const { user } = useSelector((store) => store.auth);
  const { data } = useSelector((store) => store.attendance);

  return (
    <>
      <Container disableGutters sx={{ height: "100%" }}>
        <Paper sx={{ padding: "10px", height: "100%", overflowY: "scroll" }}>
          <Box>
            <Typography textTransform={"capitalize"} variant="h4">
              time activity
            </Typography>
          </Box>
          <Timeline>
            {data.timeActivity
              ?.map((el, i) => {
                return (
                  <TimelineItem key={i} sx={classes.timelineItem}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack>
                        <Typography variant="h6" fontWeight={600}>
                          {el.status === "checkin"
                            ? "Punch In at"
                            : "Punch Out at"}
                        </Typography>
                        <Typography fontSize={"12px"} color={"gray"}>
                          {" "}
                          {format(new Date(el.dateTime), "hh:mm a")}
                        </Typography>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
              .reverse()}
          </Timeline>
        </Paper>
      </Container>
    </>
  );
}

export default TodayActivity;
