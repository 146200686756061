import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../../components/Header";
import Bio from "../components/Bio";
import { grey } from "@mui/material/colors";
import PersonalInfo from "../components/PersonalInfo";
import EmergencyContact from "../components/EmergencyContact";
import BankInformation from "../components/BankInformation";
import FamilyInformations from "../components/FamilyInfo";
import EducationInfo from "../components/EducationInfo";
import Experience from "../components/Experience";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";

function ProfileMain() {
  const [value, setValue] = useState("1");
  const { user } = useSelector((store) => store.auth);
  const BioArray = [
    { key: "Phone", value: user.phone },
    { key: "Email", value: user.email },
    { key: "Birthday", value: user.birthday },
    { key: "Address", value: user.address },
    { key: "Gender", value: user.gender },
    { key: "Reports to", value: user.reportsTo },
  ];

  const PersonalInfoArray = [
    { key: "Passport No.", value: user.personalInformation.passportNumber },
    {
      key: "Passport Exp Date.",
      value: user.personalInformation.PassportExpDate,
    },
    { key: "Tel", value: user.phone },
    { key: "Nationality", value: user.personalInformation.nationality },
    { key: "Religion", value: user.personalInformation.religion },
    { key: "Marital status", value: user.personalInformation.maritalStatus },
    {
      key: "Employment of spouse  ",
      value: user.personalInformation.employmentOfSpouse,
    },
    { key: "No. of children", value: user.personalInformation.NoOfChildren },
  ];

  const BankInformationArray = [
    { key: "Bank name", value: user.bankInformation.bankName },
    {
      key: "Bank account No.",
      value: user.bankInformation.bankAccountNumber,
    },
    { key: "IFSC Code", value: user.bankInformation.ifscCode },
    { key: "PAN No", value: user.bankInformation.PanNo },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container disableGutters maxWidth="none" sx={{ padding: "30px" }}>
        <Header title={"Profile"} subtitle={"Dashboard/Profile"} />
        <Box gridColumn="span 12">
          <Bio BioArray={BioArray} />
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} indicatorColor="primary">
                <Tab
                  sx={{
                    fontSize: "15px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  label="Profile"
                  value="1"
                />
                <Tab
                  sx={{
                    fontSize: "15px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  label="Projects"
                  value="2"
                />
                <Tab
                  sx={{
                    fontSize: "15px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  label="Bank & Statutory(Admin Only)"
                  value="3"
                />
                <Tab
                  sx={{
                    fontSize: "15px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  label="Assets"
                  value="4"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box
                border="1px"
                display="grid"
                gridTemplateColumns="repeat(12,1fr)"
                gridAutoRows="auto"
                gap="20px"
              >
                <Box gridColumn="span 6">
                  <PersonalInfo PersonalInfoArray={PersonalInfoArray} />
                </Box>
                <Box gridColumn="span 6">
                  <EmergencyContact
                    EmergencyContactArray={user.emergencyContact}
                  />
                </Box>
                <Box gridColumn="span 6">
                  <BankInformation
                    BankInformationArray={BankInformationArray}
                  />
                </Box>
                <Box gridColumn="span 6">
                  <FamilyInformations
                    FamilyInfoArray={user.familyInformation}
                  />
                </Box>
                <Box gridColumn="span 6">
                  <EducationInfo
                    EducationInfoArray={user.educationInformation}
                  />
                </Box>
                <Box gridColumn="span 6">
                  <Experience ExperienceArray={user.experience} />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item four</TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
}

export default ProfileMain;
