import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  forgotApi,
  loginApi,
  resetApi,
  signupApi,
} from "../actions/auth.action";
import { PURGE } from "redux-persist";

const initialState = {
  authstate: {
    token: localStorage.getItem("token") || "",
    isAuth: localStorage.getItem("token") ? true : false,
  },
  role: "",
  user: {},
  isLoading: false,
  error: false,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    changeRoleAction: async (state, action) => {
      if (action.type === "/post/login") {
        const token = localStorage.getItem("token") || null;
        try {
          let decoded = await jwt_decode(token);
          return (state.role = decoded.role);
        } catch (error) {
          return console.log(error);
        }
      }
      if (action.type === "/post/logout") {
        return (state.role = "");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    // --------------Login Api --------------------
    builder.addCase(loginApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.authstate.isAuth = true;
      state.authstate.token = action.payload.token;
      state.user = action.payload.user;
    });
    builder.addCase(loginApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });

    // ----------------------Sign up Api -----------------
    builder.addCase(signupApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signupApi.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(signupApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
    // ----------------------Forgot Password Api -----------------
    builder.addCase(forgotApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(forgotApi.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(forgotApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
    // ----------------------Reset Password Api -----------------
    builder.addCase(resetApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resetApi.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(resetApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export const { changeRoleAction } = authSlice.actions;

export default authSlice.reducer;
