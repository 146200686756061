import { toast } from "react-toastify";

function sendToast(message, status) {
  // -------------------Information --------------------
  if (status >= 100 && status < 200) {
    return toast.info(message, {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  }

  // ------------------success ----------------
  if (status >= 200 && status < 300) {
    return toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  }
  if (status >= 300 && status < 400) {
  }

  //   -------------------Warnign----------------------
  if (status >= 400 && status < 500) {
    return toast.warn(message, {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  }

  //   ---------------------Error ----------------------
  if (status >= 500 && status < 600) {
    return toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  }

  //   -------------------------Default---------------------
  return toast(message, {
    position: "top-right",
    autoClose: 3000,
    theme: "colored",
  });
}

export default sendToast;
