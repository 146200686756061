import {
  Box,
  Button,
  Card,
  FormHelperText,
  MenuItem,
  Modal,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import React from "react";
import { useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

function AddLeave() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const dispatchRedux = useDispatch();
  const [fromvalue, onChangeFrom] = useState(new Date());
  const [tovalue, onChangeTo] = useState(new Date());
  const [formstate, setFormstate] = useState({
    leaveType: "",
    reason: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormstate({
      ...formstate,
      [name]: value
    });
  };

  const handleSubmit = () => {
    console.log(formstate);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="warning">
        Apply Leave
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box width={"25%"} margin={"auto"} sx={{ wordSpacing: "5px" }}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: "500px",
              width: "100%",
              margin: "auto",
              borderRadius: "10px",
              position: "absolute",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="22px"
              fontWeight="500"
              padding="30px 30px 0px"
            >
              Add Leave
              <Button
                sx={{
                  backgroundColor: "#FF7444",
                  height: "30px",
                  width: "30px",
                  border: "1px solid #FF7444",
                  borderRadius: "50%",
                  fontSize: "20px",
                  ":hover": {
                    backgroundColor: "transparent",
                    color: "#FF7444",
                  },
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={handleClose}
              >
                <CloseOutlined
                  sx={{
                    fontSize: "18px",
                  }}
                />
              </Button>
            </Typography>
            <Stack
              display="grid"
              gridTemplateColumns="repeat(12,1fr)"
              gridAutoRows="auto"
              gap="20px"
              padding="30px"
            >
              <Box gridColumn="span 12">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Leave Type *
                </FormHelperText>
                <TextField
                  fullWidth
                  select
                  defaultValue="1"
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  name="leaveType"
                >
                  <MenuItem value="1" hidden={true}>
                    Select Leave Type
                  </MenuItem>
                  <MenuItem value="2">Casual Leave 12 Days</MenuItem>
                  <MenuItem value="3">Medical Leave</MenuItem>
                  <MenuItem value="4">Loss of Pay</MenuItem>
                </TextField>
              </Box>
              <Box gridColumn="span 12">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  From *
                </FormHelperText>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  onChange={handleChange}
                  name="startDate"
                /> */}
                <DatePicker
                  clearIcon={true}
                  yearPlaceholder="YYYY"
                  monthPlaceholder="MM"
                  dayPlaceholder="DD"
                  className={"add_leave_date_picker"}
                  value={fromvalue}
                  onChange={(value) => {
                    onChangeFrom(value);
                    handleChange(value);
                  }}
                  name="startDate"
                />
              </Box>
              <Box gridColumn="span 12">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  To *
                </FormHelperText>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  onChange={handleChange}
                  name="endDate"
                />
              </Box>
              <Box gridColumn="span 12">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Leave Reason *
                </FormHelperText>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  minRows="5"
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  name="reason"
                />
              </Box>

              <Box gridColumn="span 12" margin="auto">
                <Button
                  sx={{
                    margin: "auto",
                    borderRadius: "25px",
                    width: "200px",
                    bgcolor: "#fd8e2d",
                    fontSize: "16px",
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "#fd8e2d",
                    },
                  }}
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AddLeave;
