import {
  Box,
  Button,
  Card,
  CardMedia,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const EarningArray = [
  {
    item: "Basic Salary",
    earn: "$6500",
  },
  {
    item: "House Rent Allowance (H.R.A.)",
    earn: "$55",
  },
  {
    item: "Conveyance",
    earn: "$55",
  },
  {
    item: "Other Allowance",
    earn: "$55",
  },
  {
    item: "Total Earnings",
    earn: "$55",
  },
];
const DeductionArray = [
  {
    item: "Tax Deducted at Source (T.D.S.)",
    deduct: "$0",
  },
  {
    item: "Provident Fund",
    deduct: "$0",
  },
  {
    item: "ESI",
    deduct: "$0",
  },
  {
    item: "Loan",
    deduct: "$300",
  },
  {
    item: "Total Deductions",
    deduct: "$59698",
  },
];

function PayslipCard() {
  return (
    <Card component={Box} p={2} sx={{ wordSpacing: 6,boxShadow:"unset !important" }}>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          textAlign: "center",
          textDecoration: "underline",
          textTransform: "uppercase",
          margin: "0 0 20px",
        }}
      >
        PAYSLIP FOR THE MONTH OF FEB 2019
      </Typography>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <Box width="50%">
          <CardMedia
            component="img"
            image="https://smarthr.dreamguystech.com/react/template/assets/img/logo2.png"
            sx={{
              height: "auto",
              maxHeight: "100px",
              width: "auto",
              marginBottom: "20px",
            }}
          />
          <Typography fontSize={16} fontWeight={500}>
            Dreamguy's Technologies
            <br />
            3864 Quiet Valley Lane,
            <br />
            Sherman Oaks, CA, 91403
          </Typography>
        </Box>
        <Box textAlign="right">
          <Typography variant="h3" fontWeight={500} marginBottom={1}>
            PAYSLIP #49029
          </Typography>
          <Typography variant="h5" fontWeight={500}>
            Salary Month: March, 2019
          </Typography>
        </Box>
      </Stack>
      <Box marginBottom="20px">
        <Typography fontWeight="500">
          <span style={{ fontSize: "15px", fontWeight: "600" }}>John Doe</span>
          <br />
          Web Designer <br />
          Employee ID: FT-0009 <br />
          Joining Date: 1 Jan 2013
        </Typography>
      </Box>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        marginBottom="20px"
        gap="30px"
      >
        <Box width="50%">
          <Typography fontSize="18px" fontWeight="600" marginBottom="8px">
            Earnings
          </Typography>
          <Table>
            <TableBody sx={{ border: "1px solid", borderColor: "#dee2e6" }}>
              {EarningArray.map((el, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell sx={{ padding: "8px" }}>
                      <span
                        style={{
                          float: "left",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {el.item}
                      </span>
                      <span
                        style={{
                          float: "right",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {el.earn}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Box width="50%">
          <Typography fontSize="18px" fontWeight="600" marginBottom="8px">
            Deductions
          </Typography>
          <Table>
            <TableBody sx={{ border: "1px solid", borderColor: "#dee2e6" }}>
              {DeductionArray.map((el, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell sx={{ padding: "8px" }}>
                      <span
                        style={{
                          float: "left",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {el.item}
                      </span>
                      <span
                        style={{
                          float: "right",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {el.deduct}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Stack>
      <Typography>
        <strong>Net Salary: $59698</strong>&nbsp; (Fifty nine thousand six
        hundred and ninety eight only.)
      </Typography>
    </Card>
  );
}

export default PayslipCard;
