import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  InputLabel,
  useTheme,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "../../../helper/toast";
import { useDispatch } from "react-redux";
import { resetApi } from "../../../ReduxStore/actions/auth.action";

const checkoutSchema = yup.object().shape({
  password: yup.string().required("required"),
  confirmPassword: yup.string().required("required"),
});
const initialValues = {
  password: "",
  confirmPassword: "",
};

function ResetPassword() {
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();
  const resettoken = useParams();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleFormSubmit = async (values) => {
    try {
      await dispatchRedux(resetApi(values, resettoken.token));
      toast("Password changed successfully", 200);
      navigate("/login");
    } catch (error) {
      toast(error.data.message, error.status);
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        <Paper>
          <Box marginTop={"20vh"} padding={"20px"}>
            <Box textAlign={"center"}>
              <Typography variant="h2" fontWeight={600} gutterBottom>
                NEW PASSWORD
              </Typography>
              <Typography variant="h4" gutterBottom>
                set new password
              </Typography>
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box marginBottom={"20px"} mt={"50px"}>
                    <Box
                      display="grid"
                      gap="10px"
                      padding={"20px"}
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": {
                          gridColumn: isNonMobile ? undefined : "span 4",
                        },
                      }}
                    >
                      <Box gridColumn={"span 4"}>
                        <InputLabel>New Password</InputLabel>
                        <OutlinedInput
                          fullWidth
                          type={showPassword.password ? "text" : "password"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          error={!!touched.password && !!errors.password}
                          //   helperText={touched.password && errors.password}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword({
                                    password: !showPassword.password,
                                  });
                                }}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword.password ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                      <Box gridColumn={"span 4"}>
                        <InputLabel>Confirm Password</InputLabel>
                        <OutlinedInput
                          fullWidth
                          type={
                            showPassword.confirmPassword ? "text" : "password"
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmPassword}
                          name="confirmPassword"
                          error={
                            !!touched.confirmPassword &&
                            !!errors.confirmPassword
                          }
                          //   helperText={
                          //     touched.confirmPassword && errors.confirmPassword
                          //   }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword({
                                    confirmPassword:
                                      !showPassword.confirmPassword,
                                  });
                                }}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword.confirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box mt="20px" width={"50%"} margin={"auto"}>
                    <Button
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: colors.greenAccent[700],
                        fontSize: "15px",
                      }}
                      variant="contained"
                    >
                      Enter
                    </Button>
                  </Box>

                  <Box mt="20px" textAlign={"center"}>
                    <Typography variant="h5">
                      Not yet received? {/* <Link> */}
                      <Typography
                        color={colors.blueAccent[500]}
                        component={"span"}
                        variant="h5"
                        sx={{ cursor: "pointer " }}
                        onClick={() => alert("otp send successfully")}
                      >
                        Resend OTP
                      </Typography>
                      {/* </Link> */}
                    </Typography>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default ResetPassword;
