import React, { useEffect, useState } from "react";
import AddLeave from "./Pages/Employee/dashboard/Component/AddLeave";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

function Dummy() {
  const [value, onChange] = useState(new Date());

  const handleChange = (value) => {
    console.log(value);
  };

  return (
    <div>
      <AddLeave />
      <DatePicker
        value={value}
        onChange={(value) => {
          onChange(value);
          handleChange(value);
        }}
        yearPlaceholder="YYYY"
        monthPlaceholder="MM"
        dayPlaceholder="DD"
      />
    </div>
  );
}

export default Dummy;
