import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";

function PasswordOtp() {
  const [otp, setOtp] = useState("");
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Container maxWidth="sm">
      <Paper>
        <Box marginTop={"20vh"} padding={"20px"}>
          <Box textAlign={"center"}>
            <Typography variant="h2" fontWeight={600} gutterBottom>
              OTP
            </Typography>
            <Typography variant="h4" gutterBottom>
              Verification your account
            </Typography>
          </Box>
          <Box marginBottom={"20px"} mt={"50px"}>
            <MuiOtpInput value={otp} onChange={handleChange} />
          </Box>
          <Box mt="20px" width={"50%"} margin={"auto"}>
            <Button
              fullWidth
              type="submit"
              sx={{
                backgroundColor: colors.greenAccent[700],
                fontSize: "15px",
              }}
              variant="contained"
            >
              Enter
            </Button>
          </Box>
          <Box mt="20px" textAlign={"center"}>
            <Typography variant="h5">
              Not yet received?{" "}
              {/* <Link> */}
                <Typography
                  color={colors.blueAccent[500]}
                  component={"span"}
                  variant="h5"
                  sx={{cursor:"pointer "}}
                  onClick={() => alert("otp send successfully")}
                >
                  Resend OTP
                </Typography>
              {/* </Link> */}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default PasswordOtp;
