import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function PersonalInfo({PersonalInfoArray}) {
  return (
    <div>
      <Box>
        <Paper sx={{ padding: "16px" }}>
          <Typography
            variant="h4"
            sx={{
              color: "#1f1f1f",
              fontSize: "22px",
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Personal Informations
          </Typography>

          <Box>
            <Table>
              <TableBody>
                {PersonalInfoArray.map((el, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          fontSize: "16px",
                          fontWeight: "600",
                          padding: "0px 0px 5px 0px ",
                          width: "40%",
                          color: "#333333",
                        }}
                      >
                        {el.key}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          fontSize: "16px",
                          padding: "0px 0px 5px 0px ",
                          width: "60%",
                        }}
                      >
                        {el.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}

export default PersonalInfo;
