import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Dummy from "../Dummy";
import AuthenticatedRoute from "./AuthenticatedRoute";
import PrivateRoute from "./PrivateRoute";
import Admin from "../Pages/Dashboard/Admin";
import HR from "../Pages/Dashboard/HR";

// ----------Error Component-----------
import Error from "../Error_Pages/Error";

// ------------------Default Scenes Page--------------
import Team from "../scenes/team";
import Invoices from "../scenes/invoices";
import Contacts from "../scenes/contacts";
import Form from "../scenes/form";
import Calendar from "../scenes/calendar/calendar";

// ---------------Authentication------------------
import Login from "../Pages/authentication/login/Login";
import ForgotPassword from "../Pages/authentication/Component/ForgotPassword";
import PasswordOtp from "../Pages/authentication/Component/PasswordOtp";
import ResetPassword from "../Pages/authentication/Component/ResetPassword";
import CreateUser from "../Pages/authentication/Create/CreateUser";

// ---------------Employee----------------------
import FAQ from "../Pages/Employee/FAQ";
import Termsofservices from "../Pages/Employee/Team_Of_Services";
import PrivacyPolicy from "../Pages/Employee/Privacy_Policy";
import ProfileMain from "../Pages/Employee/profile/ProfileMainpage/ProfileMain";
import PaySlip from "../Pages/Employee/paySlip/PaySlip";
import AllEmployee from "../Pages/Employee/allEmployee/AllEmployee";
import Employee from "../Pages/Employee/dashboard/Employee";

function Allroute() {
  const { authState } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AuthenticatedRoute />
          </PrivateRoute>
        }
      >
        {authState.role === "admin" && <Route index element={<Admin />} />}
        {authState.role === "HR" && <Route index element={<HR />} />}
        {authState.role === "employee" && (
          <Route index element={<Employee />} />
        )}
      </Route>

      {/* ----------------------Admin------------------------ */}
      {authState.role === "admin" ? (
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AuthenticatedRoute />
            </PrivateRoute>
          }
        >
          <Route path="/admin/dashboard" element={<Admin />} />
        </Route>
      ) : null}
      {/* -----------------------------HR----------------------- */}
      {authState.role === "admin" || authState.role === "HR" ? (
        <Route
          path="/hr"
          element={
            <PrivateRoute>
              <AuthenticatedRoute />
            </PrivateRoute>
          }
        >
          <Route path="/hr/dashboard" element={<HR />} />
        </Route>
      ) : null}

      {/* -------------------------Employee----------------- */}

      {authState.role === "admin" ||
      authState.role === "HR" ||
      authState.role === "employee" ? (
        <Route
          path="/employee"
          element={
            <PrivateRoute>
              <AuthenticatedRoute />
            </PrivateRoute>
          }
        >
          <Route path="/employee/dashboard" element={<Employee />} />
          <Route path="/employee/profile/details" element={<ProfileMain />} />
          <Route path="/employee/all" element={<AllEmployee />} />
          <Route path="/employee/payslip" element={<PaySlip />} />
          <Route path="/employee/services" element={<Termsofservices />} />
          <Route path="/employee/privacy/policy" element={<PrivacyPolicy />} />
          <Route path="/employee/faq" element={<FAQ />} />
        </Route>
      ) : null}

      {/* --------------------------Theme Default Routes-------------------- */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AuthenticatedRoute />
          </PrivateRoute>
        }
      >
        <Route path="/team" element={<Team />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/form" element={<Form />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/create" element={<CreateUser />} />
      </Route>
      {/* -----------------------Authentication------------------ */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgot/password" element={<ForgotPassword />} />
      <Route path="/forgot/password/otp" element={<PasswordOtp />} />
      <Route
        path="/api/user/v1/password/reset/:token"
        element={<ResetPassword />}
      />
      {/* --------------------------Error and dummy Routes------------------ */}
      <Route
        path="*"
        element={<Error status={404} message={" Oops! Page not found!"} />}
      />
      <Route path="/dummy" element={<Dummy />} />
    </Routes>
  );
}

export default Allroute;
