import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Styled from "styled-components";
import {
  CheckInApi,
  CheckOutApi,
} from "../../../../ReduxStore/actions/attendance.action";
import { format } from "date-fns";

const TimeBar = styled(Box)({
  // border: "1px solid red",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  "& p": {
    margin: "0px",
    position: "absolute",
    top: "35px",
    fontSize: "18px",
  },
});

const PunchButton = Styled.button({
  backgroundColor: "#ff9b44",
  border: "1px solid #ff9b44",
  width: "60%",
  margin: "auto",
  color: "#fff!important",
  fontSize: "18px",
  fontWeight: "600",
  maxWidth: "100%",
  padding: "8px 40px",
  borderRadius: "50px",
  cursor: "pointer",
});

function todayTotalPersent(totalworkMinute = 0) {
  let oneMinute = 100 / 540;
  if (totalworkMinute >= 540) {
    return 100;
  }
  return totalworkMinute * oneMinute;
}

function TimeSheet() {
  const dispatchRedux = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const {
    data,
    data: { timeActivity, totalTimeSpend },
  } = useSelector((store) => store.attendance);
  const { totalBreakHours, totalBreakMinutes } =
    data === {}
      ? data.totalTimeSpend.breaksByDay[format(new Date(), "yyyy-MM-dd")]
      : {
          totalBreakHours: 0,
          totalBreakMinutes: 0,
        };
  const { overtimeHours, overtimeMinutes } =
    data === {}
      ? data.totalTimeSpend.workedByDay[format(new Date(), "yyyy-MM-dd")]
      : {
          overtimeHours: 0,
          overtimeMinutes: 0,
        };
  const handlePunchin = async () => {
    const data = { employeeID: user._id, checkIn: new Date() };
    try {
      let res = await dispatchRedux(CheckInApi(data)).wrap();
    } catch (error) {
      console.log(error);
    }
  };
  const handlePunchOut = () => {
    const data = { employeeID: user._id, checkOut: new Date() };
    dispatchRedux(CheckOutApi(data));
  };

  return (
    <Container disableGutters>
      <Paper sx={{ padding: "10px" }}>
        <Stack gap={"20px"}>
          <Box>
            <Typography variant="h4" textTransform={"capitalize"}>
              timesheet {format(new Date(), "dd MMM yyyy")}
            </Typography>
          </Box>
          <Paper variant="outlined" sx={{ padding: "8px" }}>
            <Typography variant="subtitle2" fontWeight={600}>
              {data.checkIn ? "Punch In at" : "Punch Out at"}
            </Typography>
            <Typography variant="h6">
              {timeActivity.length !== 0
                ? format(
                    new Date(timeActivity[timeActivity.length - 1]?.dateTime),
                    "E, do MMM yyyy hh.mm a"
                  )
                : null}
            </Typography>
          </Paper>
          <TimeBar>
            <CircularProgress
              sx={{
                background: "#dddddd",
                borderRadius: "100px",
                "& .MuiCircularProgress-circle": { fill: "#ffffff" },
              }}
              size={100}
              variant="determinate"
              value={todayTotalPersent(
                (data.totalTimeSpend.today?.hours || 0) * 60 +
                  (data.totalTimeSpend.today?.minutes || 0)
              )}
              color={"success"}
            />
            <p>
              {todayTotalPersent(
                data.totalTimeSpend.today?.hours * 60 +
                  data.totalTimeSpend.today?.minutes
              ) === 100
                ? "9:00"
                : (data.totalTimeSpend.today?.hours || "00") +
                  ":" +
                  (data.totalTimeSpend.today?.minutes || "00")}{" "}
              hrs
            </p>
          </TimeBar>
          {data.checkIn && (
            <PunchButton onClick={handlePunchin}>Punch In</PunchButton>
          )}
          {data.checkOut && (
            <PunchButton onClick={handlePunchOut}>Punch Out</PunchButton>
          )}
          <Stack direction={"row"} justifyContent={"space-around"}>
            <Button variant="outlined" color="secondary" size="large">
              Break {totalBreakHours + ":" + totalBreakMinutes + "hrs"}
            </Button>
            <Button variant="outlined" color="secondary" size="large">
              Overtime {overtimeHours + ":" + overtimeMinutes} hrs
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
}

export default TimeSheet;
