import {
  Box,
  Button,
  Card,
  FormHelperText,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";
import { useState } from "react";

import { CloseOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

function AddEmployee() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
 

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          margin: "auto 0px",
          borderRadius: "25px",
          width: "200px",
          bgcolor: "#fd8e2d",
          ":hover": {
            backgroundColor: "#fd8e2d",
          },
        }}
        variant="contained"
        size="large"
      >
        <AddIcon fontSize="large" />
        Add Employee
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box width={"50%"} margin={"auto"} sx={{ wordSpacing: "5px" }}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: "800px",
              width: "100%",
              margin: "auto",
              borderRadius: "10px",
              position: "absolute",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="22px"
              fontWeight="500"
              padding="30px 30px 0px"
            >
              Add Employee
              <Button
                sx={{
                  fontSize: "20px",
                  ":hover": {
                    backgroundColor: "transparent",
                    color: "#FF7444",
                  },
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={handleClose}
              >
                <CloseOutlined fontSize="medium" />
              </Button>
            </Typography>
            <Stack
              display="grid"
              gridTemplateColumns="repeat(12,1fr)"
              gridAutoRows="auto"
              gap="20px"
              padding="30px"
            >
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  First Name *
                </FormHelperText>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  sx={{ ":hover": {} }}
                />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Last Name
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="text" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Username *
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="text" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Email *
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="email" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Password
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="password" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Confirm Password
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="password" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Employee ID *
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="text" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Joining Date *
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="datetime-local" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Phone
                </FormHelperText>
                <TextField fullWidth variant="outlined" type="text" />
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Company
                </FormHelperText>
                <TextField
                  fullWidth
                  select
                  defaultValue="1"
                  variant="outlined"
                  type="text"
                >
                  <MenuItem value="1">Global Technologies</MenuItem>
                  <MenuItem value="2">Delta Infotech</MenuItem>
                </TextField>
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Department *
                </FormHelperText>
                <TextField
                  fullWidth
                  select
                  defaultValue="1"
                  variant="outlined"
                  type="text"
                >
                  <MenuItem value="1">Select Department</MenuItem>
                  <MenuItem value="2">Web Development</MenuItem>
                  <MenuItem value="3">IT Management</MenuItem>
                  <MenuItem value="4">Marketing</MenuItem>
                </TextField>
              </Box>
              <Box gridColumn="span 6">
                <FormHelperText
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Designation *
                </FormHelperText>
                <TextField
                  fullWidth
                  defaultValue="1"
                  select
                  variant="outlined"
                  type="text"
                >
                  <MenuItem value="1">Select Designation</MenuItem>
                  <MenuItem value="2">Web Designer</MenuItem>
                  <MenuItem value="3">Web Developer</MenuItem>
                  <MenuItem value="4">Android Developer</MenuItem>
                </TextField>
              </Box>
              <Box gridColumn="span 12" margin="auto">
                <Button
                  sx={{
                    margin: "auto",
                    borderRadius: "25px",
                    width: "200px",
                    bgcolor: "#fd8e2d",
                    fontSize: "16px",
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "#fd8e2d",
                    },
                  }}
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AddEmployee;
