import { useContext, useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import sendToast from "../../../helper/toast";
import { AuthContext } from "../../../Context/AuthContext";
import { CHANGE_TOKEN } from "../../../useReducer/AuthReducer";
import { persistor } from "../../../ReduxStore/store";
import { useDispatch } from "react-redux";
import { logout } from "../../../ReduxStore/actions/auth.action";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const EmployeeSidebar = () => {
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();
  const { authState, dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const tabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const handleLogout = async () => {
    // dispatchRedux(changeRoleAction({type:"/post/logout"}));
    dispatch({ type: CHANGE_TOKEN, payload: false });
    localStorage.removeItem("token");
    sendToast("Logout successfully", 200);
    try {
      await persistor.purge();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    navigate("/login");
  };

  useEffect(() => {
    if (tabletSize) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [tabletSize]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.whiteAccent[100]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
      height={"100%"}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  DUTECH
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/employee/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All Users"
              to="/employee/all"
              icon={<PersonIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Profile Details"
              to="/employee/profile/details"
              icon={<ManageAccountsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Payslip"
              to="/employee/payslip"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/employee/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Terms of services"
              to="/employee/services"
              icon={<MiscellaneousServicesIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Privacy policy"
              to="/employee/privacy/policy"
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Box onClick={handleLogout}>
              <Item
                title="Logout"
                to="/employee/payslip"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default EmployeeSidebar;
