import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

const classes = {
  timelineItem: {
    "&::before": {
      content: "none", // Remove the default content of ::before pseudo-element
    },
  },
};

function Experience({ ExperienceArray }) {
  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Paper sx={{ padding: "16px", height: "100%" }}>
          <Typography
            variant="h4"
            sx={{
              color: "#1f1f1f",
              fontSize: "22px",
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Experience
          </Typography>
          <Box>
            <Timeline>
              {ExperienceArray.map((el, i) => {
                return (
                  <TimelineItem key={i} sx={classes.timelineItem}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <List
                        sx={{
                          padding: "0px",
                          paddingBottom: "10px",
                          lineHeight: "1px",
                        }}
                      >
                        <ListItem sx={{ padding: "0px" }}>
                          <ListItemText
                            primary={el.company + " at " + el.responsibilities}
                            primaryTypographyProps={{
                              fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: 1,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ padding: "0px" }}>
                          <ListItemText
                            primary={el.startDate + " - " + el.endDate}
                            primaryTypographyProps={{
                              color: "#bdbdbd",
                              display: "block",
                              fontSize: "12px",
                              lineHeight: 1,
                            }}
                          />
                        </ListItem>
                      </List>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default Experience;
