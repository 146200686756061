import {
  Box,
  Button,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../../components/Header";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersApi } from "../../../ReduxStore/actions/user.action";
import AddEmployee from "./AddEmployee";
import { AuthContext } from "../../../Context/AuthContext";
import { useContext } from "react";

const EmployeeArray = [
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
  {
    img: "https://smarthr.dreamguystech.com/react/template/assets/img/avatar-02.jpg",
    name: "John Doe",
    roles: "Web Designer",
  },
];
const menuArray = [
  {
    designation: "Select Designation",
  },
  {
    designation: "Web Developer",
  },
  {
    designation: "Web Designer",
  },
  {
    designation: "Android Developer",
  },
  {
    designation: "IOS Developer",
  },
];

function AllEmployee() {
  const dispatchRedux = useDispatch();
  const { authState, dispatch } = useContext(AuthContext);
  const { allUser } = useSelector((store) => store.user);
  useEffect(() => {
    console.log("i am under the above useEffect");
    dispatchRedux(getAllUsersApi({ key: "aman" }));
  }, []);
  return (
    <div style={{ backgroundColor: grey[100], padding: "30px" }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={"Employee"} subtitle={"Dashboard/Employee"} />
        {(authState.role === "admin" || authState.role === "hr") && (
          <Box>
            <AddEmployee />
          </Box>
        )}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows="auto"
        gap="20px"
        marginBottom="20px"
      >
        <Box gridColumn="span 3" bgcolor="white">
          <TextField id="emply id" placeholder="Employee ID" fullWidth />
        </Box>
        <Box gridColumn="span 3" bgcolor="white">
          <TextField id="emply id" placeholder="Employee Name" fullWidth />
        </Box>
        <Box gridColumn="span 3" bgcolor="white">
          <TextField
            id="deignation"
            select
            label="DESIGNATION"
            defaultValue="Select Designation"
            variant="filled"
            fullWidth
          >
            {menuArray.map((option, i) => {
              return (
                <MenuItem
                  key={i}
                  value={option.designation}
                  sx={{
                    ":hover": {
                      backgroundColor: "#fd7e14",
                    },
                  }}
                >
                  {option.designation}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        <Box gridColumn="span 3" borderRadius="5px">
          <Button
            fullWidth
            sx={{
              bgcolor: "#55ce63",
              fontSize: "16px",
              padding: "12px",
              textAlign: "center",
              color: "white",
              textAlign: "center",
              ":hover": {
                bgcolor: "#699834",
              },
            }}
          >
            Search
          </Button>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows="auto"
        gap="20px"
      >
        {EmployeeArray.map((el, i) => {
          return (
            <Box key={i} gridColumn="span 3">
              <Card sx={{ padding: "20px" }}>
                <CardMedia
                  component="img"
                  image={el.img}
                  sx={{
                    height: "80px",
                    margin: "0 auto",
                    width: "80px",
                    borderRadius: "50%",
                  }}
                />
                <List sx={{ padding: "0px" }}>
                  <ListItem sx={{ padding: "0px" }}>
                    <ListItemText
                      primary={el.name}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#333333",
                        textAlign: "center",
                      }}
                      secondary={el.roles}
                      secondaryTypographyProps={{
                        color: "#8e8e8e",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    />
                  </ListItem>
                </List>
              </Card>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default AllEmployee;
