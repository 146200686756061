import {
  Paper,
  Container,
  Box,
  Typography,
  Stack,
  LinearProgress,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function Stastics() {
  const { user } = useSelector((store) => store.auth);
  const { data } = useSelector((store) => store.attendance);

  return (
    <>
      <Container disableGutters>
        <Paper sx={{ padding: "10px" }}>
          <Box>
            <Typography variant="h4"  textTransform={"capitalize"}>statistics</Typography>
          </Box>
          <Stack gap={"20px"}>
            <PerformanceComp
              period={"today"}
              value={
                data.totalTimeSpend.today?.hours * 60 +
                data.totalTimeSpend.today?.minutes
              }
              day={"Today"}
              time={`${data.totalTimeSpend.today?.hours || 0} / 9 hrs`}
              progressColor={"primary"}
            />
            <PerformanceComp
              period={"thisWeek"}
              value={
                data.totalTimeSpend.thisWeek?.hours * 60 +
                data.totalTimeSpend.thisWeek?.minutes || 0
              }
              day={"This Week"}
              time={`${data.totalTimeSpend.thisWeek?.hours || 0} / 45 hrs`}
              progressColor={"secondary"}
            />
            <PerformanceComp
              period={"thisMonth"}
              value={
                data.totalTimeSpend.thisMonth?.hours * 60 +
                data.totalTimeSpend.thisMonth?.minutes || 0
              }
              day={"This Month"}
              time={`${data.totalTimeSpend.thisMonth?.hours || 0} / 198 hrs`}
              progressColor={"error"}
            />
            <PerformanceComp
              period={"remaining"}
              day={"Remaining"}
              time={`90 / 198 hrs`}
              progressColor={"warning"}
            />
            <PerformanceComp
              period={"overtime"}
              day={"Overtime"}
              time={"4"}
              progressColor={"info"}
            />
          </Stack>
        </Paper>
      </Container>
    </>
  );
}

export default Stastics;

function totalTimePersent(totalworkMinute = 0, period) {
  if (period === "today") {
    let oneMinute = 100 / 540;
    if (totalworkMinute >= 540) {
      return 100;
    }
    return totalworkMinute * oneMinute;
  }
  if (period == "thisWeek") {
    let oneMinute = 100 / 2700;
    if (totalworkMinute >= 2700) {
      return 100;
    }
    return totalworkMinute * oneMinute;
  }
  if (period == "thisMonth") {
    let oneMinute = 100 / 11880;
    if (totalworkMinute >= 11880) {
      return 100;
    }
    return totalworkMinute * oneMinute;
  }
  return 0;
}

function PerformanceComp({ day, time, progressColor, value, period }) {
  return (
    <Paper sx={{ padding: "5px " }}>
      <Stack spacing={1}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle1">{day}</Typography>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {time}
          </Typography>
        </Stack>
        <Box>
          <LinearProgress
            variant="determinate"
            color={progressColor}
            value={totalTimePersent(value, period)}
          />
        </Box>
      </Stack>
    </Paper>
  );
}
