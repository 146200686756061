import { createSlice } from "@reduxjs/toolkit";
import {
  CheckInApi,
  CheckOutApi,
  showActivityApi,
  totalTimeSpentApi,
} from "../actions/attendance.action";

const initialState = {
  loading: false,
  error: false,
  data: {
    checkIn: true,
    checkOut: false,
    timeActivity: [],
    totalTimeSpend: {},
  },
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // --------------Check In Api --------------------
    builder.addCase(CheckInApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(CheckInApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data.checkIn = false;
      state.data.checkOut = true;
    });
    builder.addCase(CheckInApi.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    // --------------Check Out Api --------------------
    builder.addCase(CheckOutApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(CheckOutApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data.checkIn = true;
      state.data.checkOut = false;
    });
    builder.addCase(CheckOutApi.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    // --------------Show time Api --------------------
    builder.addCase(showActivityApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(showActivityApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data.timeActivity = action.payload.data[0].mergedArray;
      if (
        action.payload.data[0].mergedArray[
          action.payload.data[0].mergedArray.length - 1
        ].status === "checkin"
      ) {
        state.data.checkIn = false;
        state.data.checkOut = true;
      } else {
        state.data.checkIn = true;
        state.data.checkOut = false;
      }
    });
    builder.addCase(showActivityApi.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    // --------------Total Time Spent Api --------------------
    builder.addCase(totalTimeSpentApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(totalTimeSpentApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data.totalTimeSpend = action.payload.result;
    });
    builder.addCase(totalTimeSpentApi.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default attendanceSlice.reducer;
