import { createAsyncThunk} from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/configVariables";
import axios from "axios";

export const CheckInApi = createAsyncThunk(
  "put/checkin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/attendance/v1/checkIn`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);


export const CheckOutApi = createAsyncThunk(
  "put/checkout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/attendance/v1/checkOut`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);


export const showActivityApi = createAsyncThunk(
  "post/showActivity",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/attendance/v1/activity`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);


export const totalTimeSpentApi = createAsyncThunk(
  "post/totalTimeSpent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/attendance/v1/totalTimeSpent`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);
