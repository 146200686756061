import { createSlice } from "@reduxjs/toolkit";
import { getAllUsersApi } from "../actions/user.action";

const initialState = {
  allUser: [],
  isLoading: false,
  error: false,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // --------------Upcoming Holiday Api --------------------
    builder.addCase(getAllUsersApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUsersApi.fulfilled, (state, action) => {
      console.log("payloadpayloadpayloadpayloadpayload",action.payload);
      state.isLoading = false;
      state.upcomingHoliday = action.payload.data;
    });
    builder.addCase(getAllUsersApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export default userSlice.reducer;
