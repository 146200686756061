import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/configVariables";
import axios from "axios";

export const upcomingHolidayApi = createAsyncThunk(
  "get/upcomingHoliday",
  async (date, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/holiday/v1/upcomingHoliday?date=${date}`
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);
