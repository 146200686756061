import { Box, Typography, Container } from "@mui/material";
import React from "react";

function Termsofservices() {
  return (
    <div style={{ color: "black", wordSpacing: "8px", textAlign: "justify" }}>
      <Box sx={{ padding: "30px" }}>
        <Container
          sx={{
            margin: "auto",
            padding: "30px",
            maxWidth: "1100px !important",
          }}>
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "30px",
            }}>
            Terms of Services
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "1rem",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip.
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: "0.5rem" }}>
            Etiam blandit lacus
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: "0.5rem" }}>
            Maecenas sit amet
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed1
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Container>
      </Box>
    </div>
  );
}

export default Termsofservices;
