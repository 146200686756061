import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/configVariables";
import axios from "axios";

export const getAllUsersApi = createAsyncThunk(
  "get/allusers",
  async (data,{ rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/v1/admin/users`
      );
      return response.data;
    } catch (err) {
        console.log(err)
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);
