import { Box, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import EmployeeSidebar from "../scenes/global/sidebar/EmployeeSidebar";
import Topbar from "../scenes/global/Topbar";
import AdminSidebar from "../scenes/global/sidebar/AdminSidebar";
import { AuthContext } from "../Context/AuthContext";
import HRSidebar from "../scenes/global/sidebar/HRSidebar";

function AuthenticatedRoute() {
  const [isSidebar, setIsSidebar] = useState(true);
  const { authState, dispatch } = useContext(AuthContext);
  return (
    <>
      <Stack direction={"row"} height={"100%"}>
        <Box>
          {authState.role === "employee" && (
            <EmployeeSidebar isSidebar={isSidebar} />
          )}
          {authState.role === "admin" && <AdminSidebar isSidebar={isSidebar} />}
          {authState.role === "HR" && <HRSidebar isSidebar={isSidebar} />}
        </Box>
        <Box width={"100%"}>
          <Topbar setIsSidebar={setIsSidebar} />
          <main className="content">
            <Outlet />
          </main>
        </Box>
      </Stack>
    </>
  );
}

export default AuthenticatedRoute;
