import { Box, Button, Stack } from "@mui/material";
import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import Header from "../../../components/Header";
import { Print } from "@mui/icons-material";
import PayslipCard from "./PayslipCard";

function PaySlip() {
  const printPdfRef = React.useRef();

  const handleDownloadPdf = async () => {
    let element = printPdfRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "JPG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };
  return (
    <>
      <Box p={4}>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          marginBottom="20px"
        >
          <Header title="Payslip" subtitle="Dashboard/Payslip" />
          <Box>
            <Stack display="flex" direction="row">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#dee2e6",
                  padding: "4px 8px",
                  fontSize: "14px",
                  borderRadius: "0.2rem",
                }}
              >
                CSV
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#dee2e6",
                  padding: "4px 8px",
                  fontSize: "14px",
                  borderRadius: "0.2rem",
                }}
                onClick={handleDownloadPdf}
              >
                PDF
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#dee2e6",
                  padding: "4px 8px",
                  fontSize: "14px",
                  borderRadius: "0.2rem",
                }}
              >
                <Print sx={{ marginRight: "1px" }} />
                Print
              </Button>
            </Stack>
          </Box>
        </Stack>
        <div ref={printPdfRef}>
            <PayslipCard />
        </div>
      </Box>
    </>
  );
}

export default PaySlip;
