import { Box, Typography, createTheme, ThemeProvider } from "@mui/material";
import React from "react";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: " 'CircularStd', sans-serif",
          wordSpacing: "5px",
          color: "#1f1f1f",
          fontWeight: "500",
        },
        h3: {
          fontSize: "26px",
          marginBottom: "5px",
        },
        h4: {
          fontSize: "18px",
          marginBottom: "8px",
          fontWeight: "bold",
        },
        h5: {
          fontSize: "16px",
          marginBottom: "5px",
        },
        subtitle1: {
          fontSize: "16px",
          marginBottom: "1rem",
        },
        caption: {
          fontSize: "15px",
          fontWeight: "400",
        },
      },
    },
  },
});

function Privacypolicies() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box padding={4}>
          <Box sx={{ fontSize: "26px", marginBottom: "30px" }}>
            <Typography variant="h3">Privacy Policies</Typography>
            <Typography variant="h5">Dashboard / Privacy Policies</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque vel sodales mauris. Nunc accumsan mi massa, ut
              maximus magna ultricies et:
            </Typography>

            <Typography variant="caption">
              Integer quam odio, ullamcorper id diam in, accumsan convallis
              libero. Duis at lacinia urna.
              <br />
              Mauris eget turpis sit amet purus pulvinar facilisis at sed lacus.
              <br />
              Quisque malesuada volutpat orci, accumsan scelerisque lorem
              pulvinar
              <br />
              vitae. Vestibulum sit amet semaliquam, vestibulum nisi sed,
              sodales libero
            </Typography>

            <Typography variant="h4">
              Aenean accumsan aliquam justo, et rhoncus est ullamcorper at
            </Typography>
            <Typography variant="subtitle1">
              Donec posuere dictum enim, vel sollicitudin orci tincidunt ac.
              Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc
              vel metus bibendum varius. Vestibulum suscipit lacinia eros a
              aliquam. Sed dapibus arcu eget egestas hendrerit.
            </Typography>
            <Typography variant="subtitle1">
              Vivamus consectetur metus at nulla efficitur mattis. Aenean
              egestas eu odio vestibulum vestibulum. Duis nulla lectus, lacinia
              vitae nibh vitae, sagittis interdum lacus. Mauris lacinia leo
              odio, eget finibus lectus pharetra ut. Nullam in semper enim, id
              gravida nulla.
            </Typography>
            <Typography variant="subtitle1">
              Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu
              eros vitae justo dictum tempor.
            </Typography>
            <Typography variant="h4">
              Etiam sed fermentum lectus. Quisque vitae ipsum libero
            </Typography>
            <Typography>
              Phasellus sit amet vehicula arcu. Etiam pulvinar dui libero, vitae
              fringilla nulla convallis in. Fusce sagittis cursus nisl, at
              consectetur elit vestibulum vestibulum:
            </Typography>
            <Typography variant="caption">
              Integer quam odio, ullamcorper id diam in, accumsan convallis
              libero. Duis at lacinia urna.
              <br />
              Mauris eget turpis sit amet purus pulvinar facilisis at sed lacus.
              <br />
              Quisque malesuada volutpat orci, accumsan scelerisque lorem
              pulvinar
              <br />
              vitae. Vestibulum sit amet semaliquam, vestibulum nisi sed,
              sodales libero
            </Typography>
            <Typography variant="h4">pulvinar</Typography>
            <Typography variant="subtitle1">
              Sed sollicitudin, diam nec tristique tincidunt, nulla ligula
              facilisis nunc, non condimentum tortor leo id ex.
            </Typography>
            <Typography variant="subtitle1">
              ivamus consectetur metus at nulla efficitur mattis. Aenean egestas
              eu odio vestibulum vestibulum. Duis nulla lectus, lacinia vitae
              nibh vitae, sagittis interdum lacus. Mauris lacinia leo odio, eget
              finibus lectus pharetra ut. Nullam in semper enim, id gravida
              nulla.
            </Typography>
            <Typography variant="subtitle1">
              Donec posuere dictum enim, vel sollicitudin orci tincidunt ac.
              Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc
              vel metus bibendum varius. Vestibulum suscipit lacinia eros a
              aliquam. Sed dapibus arcu eget egestas hendrerit.Donec posuere
              dictum enim, vel sollicitudin orci tincidunt ac. Maecenas mattis
              ex eu elit tincidunt egestas. Vivamus posuere nunc vel metus
              bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed
              dapibus arcu eget egestas hendrerit.
            </Typography>
            <Typography variant="h4">efficitur</Typography>
            <Typography variant="subtitle1">
              Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu
              eros vitae justo dictum tempor.
            </Typography>
            <Typography sx={{ fontWeight: "700", fontSize: "1rem" }}>
              Vivamus posuere nunc vel metus bibendum varius. Vestibulum
              suscipit lacinia eros a aliquam. Sed dapibus arcu eget egestas
              hendrerit.Donec posuere dictum enim, vel sollicitudin orci
              tincidunt ac.
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default Privacypolicies;
