import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function FamilyInfo({ FamilyInfoArray }) {
  return (
    <div>
      <Box>
        <Paper sx={{ padding: "16px" }}>
          <Typography
            variant="h4"
            sx={{
              color: "#1f1f1f",
              fontSize: "22px",
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Family Informations
          </Typography>

          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                    Relationship
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                    Date of Birth
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: "16px" }}>
                    Phone
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FamilyInfoArray.map((el, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell sx={{ fontWeight: 500, fontSize: "16px" }}>
                        {el.name}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: "16px" }}>
                        {" "}
                        {el.relationship}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: "16px" }}>
                        {" "}
                        {el.dob}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, fontSize: "16px" }}>
                        {el.phone}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}

export default FamilyInfo;
