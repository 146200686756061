import {
  Divider,
  Typography,
  Container,
  Paper,
  Box,
  Stack,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import AddLeave from "./AddLeave";

export function Projects() {
  return (
    <Container disableGutters>
      <Paper sx={{ padding: "10px" }}>
        <Box marginBottom={"20px"}>
          <Typography variant="h4" textTransform={"capitalize"}>
            projects
          </Typography>
        </Box>
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Stack gap={"20px"} direction={"row"}>
            <Box>
              <Typography variant="h4" color={"black"} fontWeight={700}>
                71
              </Typography>
              <Typography variant="h6" color={"#777"} fontWeight={600}>
                TOTAL TASKS
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box>
              <Typography variant="h4" color={"black"} fontWeight={700}>
                14
              </Typography>
              <Typography variant="h6" color={"#777"} fontWeight={600}>
                PENDING TASKS
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography variant="h6" color={"black"}>
              2
            </Typography>
            <Typography variant="h6">Total Projects</Typography>
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
}

export function YourLeave() {
  return (
    <Container disableGutters>
      <Paper sx={{ padding: "10px" }}>
        <Box marginBottom={"20px"}>
          <Typography variant="h4" textTransform={"capitalize"}>
            your leave
          </Typography>
        </Box>
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Stack gap={"20px"} direction={"row"}>
            <Box>
              <Typography variant="h4" color={"black"} fontWeight={700}>
                4.5
              </Typography>
              <Typography variant="h6" color={"#777"} fontWeight={600}>
                LEAVE TAKEN
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box>
              <Typography variant="h4" color={"black"} fontWeight={700}>
                12
              </Typography>
              <Typography variant="h6" color={"#777"} fontWeight={600}>
                REMAINING
              </Typography>
            </Box>
          </Stack>
          <Box>
            <AddLeave />
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
}

export function UpcomingHoliday() {
  const { upcomingHoliday } = useSelector((store) => store.holiday);
  return (
    <Container disableGutters>
      <Paper sx={{ padding: "10px" }}>
        <Box marginBottom={"20px"}>
          <Typography variant="h4" textTransform={"capitalize"}>
            upcoming holiday
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" color={"black"} fontWeight={700}>
            {Object.keys(upcomingHoliday).length !== 0
              ? format(new Date(upcomingHoliday.holidayDate), "E, do MMM yyyy")
              : null}
            - {upcomingHoliday.title}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}
