import { createSlice } from "@reduxjs/toolkit";
import { upcomingHolidayApi } from "../actions/holiday.action";

const initialState = {
  upcomingHoliday: {},
  isLoading: false,
  error: false,
};

const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // --------------Upcoming Holiday Api --------------------
    builder.addCase(upcomingHolidayApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(upcomingHolidayApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.upcomingHoliday = action.payload.data;
    });
    builder.addCase(upcomingHolidayApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export default holidaySlice.reducer;
